<template>
  <section>
    <a-row class="search-container" type="flex" justify="space-between">
      <a-form
        :form="serviveForm"
        layout="inline"
        @submit="submitForm"
        class="m-t-14"
      >
        <a-form-item label="关键字查询">
          <a-input v-decorator="['materialsName']" placeholder="请输入" />
        </a-form-item>

        <a-form-item>
          <a-button type="primary" class="m-r-8" html-type="submit">查询</a-button>
          <a-button class="m-r-8" @click="reset">重置</a-button>
          <!-- <a-button>添加维保单</a-button> -->
        </a-form-item>
      </a-form>

      <a-button type="primary" class="m-t-18" @click="addMajor">添加专业</a-button>
    </a-row>

    <section class="list-table-container list-table m-t-24">
      <a-table
        :bordered="true"
        v-if="data && data.length"
        :expandedRowKeys="defaultExpandedRowKeys"
        @expand="onexpand"
        :expandRowByClick="true"
        :columns="columns"
        :data-source="data"
        :pagination="false"
      >
        <template slot="operation" slot-scope="list, text">
          <span v-if="!text.parentId">
            <a @click="addSecond(text.name, text.id, text.parentId, true)"
              >新增二级分类</a
            >
            <a-divider type="vertical" />
          </span>
          <a
            @click="
              addSecond(
                text.name,
                text.id,
                text.parentId,
                false,
                text.fathername
              )
            "
            >编辑</a
          >
          <a-divider type="vertical" />
          <a @click="deleteCategory(text.id)">删除</a>
        </template>
        <template slot="level" slot-scope="list, text">
          <a>{{ text.parentId ? "二级分类" : "专业分类" }}</a>
        </template>
      </a-table>
    </section>
    <!-- 专业分类 -->
    <a-modal
      centered
      width="660px"
      @cancel="cancelModal"
      class="modal-container"
      :title="typeModal.title"
      v-model="typeModal.visible"
      :footer="false"
    >
      <a-form :form="form" @submit="submitSecondForm">
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="所属专业"
        >
          <a-input
            :disabled="disableds || (parentId ? true : false)"
            autocomplete="off"
            v-decorator="[
              'name',
              { rules: [{ required: true, message: '所属专业不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>

        <a-form-item
          v-if="disableds || parentId || addMajorStatus"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="分类名称"
        >
          <a-input
            autocomplete="off"
            placeholder="请输入"
            v-decorator="[
              'secondName',
              { rules: [{ required: true, message: '类型名称不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>
        <a-row class="form-btn-group">
          <a-button class="btn-save" html-type="submit"
            ><a-icon type="save" />保存</a-button
          >
          <a-button class="btn-cancel" @click="cancelModal">取消</a-button>
        </a-row>
      </a-form>
    </a-modal>

    <!-- 添加专业 -->
    <a-modal
      centered
      width="660px"
      @cancel="cancelModal"
      class="modal-container"
      :title="typeModals.title"
      v-model="typeModals.visible"
      :footer="false"
    >
      <a-form :form="addform" @submit="addMajorData">
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="所属专业"
        >
          <a-input
            autocomplete="off"
            v-decorator="[
              'name',
              { rules: [{ required: true, message: '所属专业不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>

        <a-form-item
          v-if="parentId"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="分类名称"
        >
          <a-input
            autocomplete="off"
            placeholder="请输入"
            v-decorator="[
              'secondName',
              { rules: [{ required: true, message: '类型名称不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>
        <a-row class="form-btn-group">
          <a-button class="btn-save" html-type="submit"
            ><a-icon type="save" />保存</a-button
          >
          <a-button class="btn-cancel" @click="cancelModal">取消</a-button>
        </a-row>
      </a-form>
    </a-modal>
  </section>
</template>
<script>
export default {
  data() {
    return {
      serviveForm: this.$form.createForm(this),
      form: this.$form.createForm(this),
      addform: this.$form.createForm(this),
      disableds: false,
      addMajorStatus: false,
      defaultExpandedRowKeys: [],
      editStatus: true,
      parentId: "",
      selectedRowKeys: [],
      typeModal: {
        visible: false,
        title: "添加",
      },
      typeModals: {
        visible: false,
        title: "修改",
      },
      columns: [
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "层级",
          dataIndex: "level",
          key: "age",
          scopedSlots: { customRender: "level" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],
      data: [],
      majorId: "",
    };
  },
  created() {
    this.getCategoryList();
  },
  methods: {
    // 默认展开所有行
    setDefaultExpandedKeys() {
      let arr = [];
      setTimeout(() => {
        //单独设置--展开所有功能
        let data = this.data;
        data.length &&
          data.forEach((item) => {
            if (item.children && item.children.length) {
              arr.push(item.id);
              item.children.forEach((item) => {
                arr.push(item.id);
                if (item.children && item.children.length) {
                  arr.push(item.id);
                  item.children.forEach((item) => {
                    arr.push(item.id);
                  });
                }
              });
            }
          });
        this.defaultExpandedRowKeys = arr;
      }, 200);
    },
    onexpand(expanded, record) {
      if (expanded) {
        // 设置展开窗Key
        this.defaultExpandedRowKeys.push(record.id);
      } else {
        if (this.defaultExpandedRowKeys.length) {
          this.defaultExpandedRowKeys = this.defaultExpandedRowKeys.filter(
            (v) => {
              return v != record.id;
            }
          );
        }
      }
      // console.log( this.defaultExpandedRowKeys)
    },
    reset() {
      this.serviveForm.resetFields();
      this.getCategoryList();
    },

    // 列表
    getCategoryList() {
      this.$api.getCategoryList().then((res) => {
        if (res.code == "200") {
          let arr = this.formTreeData(res.data);
          console.log(arr);

          this.data = arr;
          this.setDefaultExpandedKeys();
        }
      });
    },
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.serviveForm.getFieldsValue();
      let materialsName = formVal.materialsName;
      let res = this.loop(this.data, materialsName);
      console.log(res);

      this.data = res;
      let arr = [];
      this.data.forEach((item) => {
        if (item.children && item.children.length) {
          arr.push(item.id);
          item.children.forEach((item) => {
            arr.push(item.id);
            if (item.children && item.children.length) {
              arr.push(item.id);
              item.children.forEach((item) => {
                arr.push(item.id);
              });
            }
          });
        }
      });
      this.defaultExpandedRowKeys = arr;
    },
    // 添加专业
    addMajorData(e) {
      e && e.preventDefault();

      this.addform.validateFields((err, values) => {
        if (!err) {
          let params = {
            name: values.name,
          };
          this.$api.addCategory(params).then((res) => {
            if (res.code == "200") {
              this.$message.success("新增成功!");
              this.typeModals.visible = false;
              this.addform.resetFields();
              this.getCategoryList();
            }
          });
        }
      });
      // let formVal = this.addform.getFieldsValue();
      // console.log(formVal);
    },
    // 添加分类
    submitSecondForm(e) {
      e && e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let formVal = values;
          let params = {};
          if (this.parentId) {
            // console.log("我要修改二级分类");
            params = {
              name: formVal.secondName,
              id: this.majorId,
            };
            //console.log(params);
            this.$api.editCategory(params).then((res) => {
              if (res.code == "200") {
                // this.$message.success("xiug!");
                this.typeModal.visible = false;
                this.form.resetFields();
                this.getCategoryList();
              }
            });
          } else {
            if (formVal.secondName) {
              // 新增二级分类
              params = {
                name: formVal.secondName,
                parentId: this.majorId,
              };
              console.log(params);
              this.$api.addCategory(params).then((res) => {
                if (res.code == "200") {
                  this.$message.success("新增成功!");
                  this.typeModal.visible = false;
                  this.form.resetFields();
                  this.getCategoryList();
                }
              });
            } else {
              //console.log("我修改一级分类");
              params = {
                name: formVal.name,
                id: this.majorId,
              };
              // console.log(params);
              this.$api.editCategory(params).then((res) => {
                if (res.code == "200") {
                  // this.$message.success("xiug!");
                  this.typeModal.visible = false;
                  this.form.resetFields();
                  this.getCategoryList();
                }
              });
            }
          }
        }
      });
      // let params = {};
      // console.log(params);
      // let formVal = this.form.getFieldsValue();
      // if (this.parentId) {
      //   // console.log("我要修改二级分类");
      //   params = {
      //     name: formVal.secondName,
      //     id: this.majorId,
      //   };
      //   //console.log(params);
      //   this.$api.editCategory(params).then((res) => {
      //     if (res.code == "200") {
      //       // this.$message.success("xiug!");
      //       this.typeModal.visible = false;
      //       this.form.resetFields();
      //       this.getCategoryList();
      //     }
      //   });
      // } else {
      //   if (formVal.secondName) {
      //     // 新增二级分类
      //     params = {
      //       name: formVal.secondName,
      //       parentId: this.majorId,
      //     };
      //     console.log(params);
      //     this.$api.addCategory(params).then((res) => {
      //       if (res.code == "200") {
      //         this.$message.success("新增成功!");
      //         this.typeModal.visible = false;
      //         this.form.resetFields();
      //         this.getCategoryList();
      //       }
      //     });
      //   } else {
      //     //console.log("我修改一级分类");
      //     params = {
      //       name: formVal.name,
      //       id: this.majorId,
      //     };
      //     // console.log(params);
      //     this.$api.editCategory(params).then((res) => {
      //       if (res.code == "200") {
      //         // this.$message.success("xiug!");
      //         this.typeModal.visible = false;
      //         this.form.resetFields();
      //         this.getCategoryList();
      //       }
      //     });
      //   }
      // }
    },
    // 修改
    submitEditSecondForm(e) {
      e && e.preventDefault();
      let formVal = this.editform.getFieldsValue();
      console.log(formVal);
    },
    // 删除
    deleteCategory(id) {
      this.$api.deleteCategory(id).then((res) => {
        if (res.code == "200") {
          this.getCategoryList();
        }
      });
    },
    // 关闭模态框
    cancelModal() {
      this.typeModal.visible = false;
      this.typeModals.visible = false;
      this.disableds = false;
      this.addMajorStatus = false;
      this.parentId = "";
      this.form.resetFields();
      this.addform.resetFields();
    },
    // 选择添加设备
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    addMajor() {
      this.typeModals.visible = true;
      this.typeModals.title = "添加";
    },
    // 添加
    addSecond(name, id, parentId, status, fathername) {
      console.log(name, id, status, parentId, fathername);
      // if(name) this.addMajorStatus=true;
      this.typeModal.title = status == true ? "添加" : "编辑";

      this.disableds = status;
      this.parentId = parentId;
      this.majorId = id;
      if (parentId) {
        setTimeout(() => {
          this.form.setFieldsValue({
            name: fathername,
            secondName: name,
          });
        }, 0);
      } else {
        setTimeout(() => {
          this.form.setFieldsValue({
            name: name,
          });
        }, 0);
      }

      this.typeModal.visible = true;
    },
    // 修改
    editModal(name, id, parentId) {
      console.log(name, id, parentId);
      this.parentId = parentId;
      setTimeout(() => {
        this.editform.setFieldsValue({
          name: name,
        });
      }, 0);
      this.typeModals.visible = true;
    },
    // 树状图
    formTreeData(arr) {
      if (arr && arr.length > 0) {
        arr.forEach((item) => {
          item.key = item.id;
          item.title = item.name;

          // item.children.fathername=item.name;
          item.scopedSlots = { title: "value" };
          if (item.children && item.children.length > 0) {
            item.children.forEach((items) => {
              items.fathername = item.name;
            });
            this.formTreeData(item.children);
          }
        });
        return arr;
      }
    },
    // 搜索
    loop(array, filterKey) {
      let filterArr = array.map((item) => {
        let childs = item.children ? this.loop(item.children, filterKey) : [];

        if (!item.name.includes(filterKey) && childs.length < 1) return null;
        return item;
      });

      return filterArr.filter((item) => item);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.ant-table-row-expand-icon {
  background: #1c3d6c;
}
</style>